import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`If you need specific experience and expertise finding the right people can be a challenge.
Does your company need a temporary talent injection, or a long-term technical partnership with trust, experience and talent in software engineers? In any case, we can provide expertise to achieve a successful digital result at the right time.`}</p>
    </PageDescription>
    <h2>{`Team Augmentation Services`}</h2>
    <Row className="post-video-row" mdxType="Row">
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/teamaug.svg",
            "alt": "Team Augmentation"
          }}></img>
        </div>
      </Column>
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <br />
Recruitment in the software world is one of the biggest challenges. Finding the right applicants with sufficient experience and skills at the right price can be extremely expensive and time-consuming. This is why many businesses are now using resources to expand their workforce on demand.
        <p><strong parentName="p">{`Clients use Wizbord’s Team Augmentation services to:`}</strong></p>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Save time and money through speeding up the search to the induction process; `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Improve the overall quality level; `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Prove full flexibility with a team scale up and down option; `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`maintain project monitoring while still benefiting outside teams.`}</p>
          </li>
        </ul>
        <p><strong parentName="p">{`Talented staff on demand`}</strong></p>
        <p>{`Wizbord will appoint skilled people to internal teams. We provide an experienced workforce in the production of backend and frontent.`}</p>
        <p><strong parentName="p">{`With our Augmented Team services you get:`}</strong></p>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Sliding rate discounts, based on the length of a contract; `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`flexible management options; employees who work under your own management or fully managed teams – or both;`}</p>
          </li>
        </ul>
        <p>{`Customers can improve on-site expert groups without inflating hires costs and expensive job procedures thanks to the increased team service provided by Wizbord. Team members of Wizbord are trained in seamless integration and are able to align themselves efficiently with your goals within an agreed time schedule, with a high-quality code value approach and with team members from Wizbord for fast, realistic resolution of questions. We live in our customers’ shoes open, open-minded and honest. This means that extended teams work as a consistent unit with a focus on efficient delivery and incomparable quality.`}</p>
        <p><strong parentName="p">{`Working with Wizbord`}</strong></p>
        <p>{`Wizbord services offer its customers the best possible value when they need it, at a predictable rate. Our contracts start with a one-month commitment and can be extended to meet a customer’s needs as they change over time. We recognize the importance of flexibility in development projects and work with our customers to prepare a plan to meet their particular needs.`}</p>
        <p><strong parentName="p">{`Team config matches your needs`}</strong></p>
        <p>{`Offshore, near-shore, hybrid, on-site. Cases of use: replacement for emergencies, assistance for legacy systems, lack of experience and more.`}</p>
        <p><strong parentName="p">{`Flexibility in scale`}</strong></p>
        <p>{`We offer a number of specialists and other parameters, in contrast to your own team, with flexible options to add or reduce.`}</p>
        <p><strong parentName="p">{`Qualification`}</strong></p>
        <p>{`To satisfy your business requirements, we offer team training and management. There are fully managed and customer management options available.`}</p>
        <p><strong parentName="p">{`Security`}</strong></p>
        <p>{`We create a high degree of safety, including background monitoring and legal support as your company requests.`}</p>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      